var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title',[_vm._v("Galactiq | Mindustry")]),_vm._m(0),_c('section',{staticClass:"content-row content-row-gray content-row-color"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header"},[_c('div',{staticClass:"tab-group tab-group-switch-style"},[_c('ul',[_c('li',{class:_vm.$root.$children[0].period == 1 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(1)}}},[_vm._v(" 1 month ")]),_c('li',{class:_vm.$root.$children[0].period == 3 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(3)}}},[_vm._v(" 3 months ")]),_c('li',{class:_vm.$root.$children[0].period == 6 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(6)}}},[_vm._v(" 6 months ")]),_c('li',{class:_vm.$root.$children[0].period == 12 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(12)}}},[_vm._v(" 12 months ")])])])]),_c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(1),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(1))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(4),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(1.50))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(7),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(2))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(8),_vm._m(9)])])])])]),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-color content-gamebanner"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header content-header-large content-header-uppercase"},[_c('h1',[_c('mark',[_vm._v("Mindustry")])]),_c('p',[_vm._v(" An open-ended tower-defense game with a focus on resource management ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/mindustry/copper.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Copper ")]),_c('p',[_vm._v("Mindustry Copper"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("1")]),_vm._v(" CPU core")]),_c('li',[_c('strong',[_vm._v("512 MB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("2 GB")]),_vm._v(" Disk space")]),_c('li',[_c('strong',[_vm._v("1 ")]),_vm._v(" Backup")]),_c('li',[_c('strong',[_vm._v("0")]),_vm._v(" Databases")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/mindustry/graphite.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Graphite ")]),_c('p',[_vm._v("Mindustry Graphite"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("1.5")]),_vm._v(" CPU core")]),_c('li',[_c('strong',[_vm._v("512 MB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("2 GB")]),_vm._v(" Disk space")]),_c('li',[_c('strong',[_vm._v("1 ")]),_vm._v(" Backups")]),_c('li',[_c('strong',[_vm._v("0")]),_vm._v(" Databases")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/mindustry/titanium.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Titanium ")]),_c('p',[_vm._v("Mindustry Titanium"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("1.5")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("1 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("6 GB")]),_vm._v(" Disk space")]),_c('li',[_c('strong',[_vm._v("2 ")]),_vm._v(" Backups")]),_c('li',[_c('strong',[_vm._v("0")]),_vm._v(" Databases")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-gray"},[_c('div',{staticClass:"container"},[_c('div',[_c('table',[_c('caption',[_vm._v(" Mindustry Addons ")]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Subdomain (max 1)")]),_c('td',[_vm._v("$1.00 "),_c('small',[_vm._v("/ month")])])]),_c('tr',[_c('td',[_vm._v("Additional port (max 2)")]),_c('td',[_vm._v("$0.20 "),_c('small',[_vm._v("/ month")])])]),_c('tr',[_c('td',[_vm._v("1GB Extra storage (max 50)")]),_c('td',[_vm._v("$0.15 "),_c('small',[_vm._v("/ month")])])]),_c('tr',[_c('td',[_vm._v("0.5 Extra Cores (max 1)")]),_c('td',[_vm._v("$1.50 "),_c('small',[_vm._v("/ month")])])])])])])])])
}]

export { render, staticRenderFns }